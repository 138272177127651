import { PrismicRichText } from "@prismicio/react";
import { LinkButton, ColorPicker, useWindowDimensions } from "../";
import Styles from "./TwoColumns.module.css";

const TwoColumns = ({
	primary: {
		background_color,
		column_width,
		column_heading,
		column_heading_color,
		column_subheading,
		column_subheading_color,
		cta_color,
		cta_label,
		cta_link,
		description,
		description_color,
		full_image,
		heading,
		image,
		image_right,
		image_width,
		subheading,
		section_heading,
	},
}) => {
	const { mobile } = useWindowDimensions();
	const ctaStyles = {
		backgroundColor: cta_color ? "var(--background-light)" : "var(--font-dark)",
		color: cta_color ? "var(--font-dark)" : "var(--font-light)",
		border: cta_color && "2px solid var(--font-dark)",
	};

	return (
		<div
			className={Styles.wrapper}
			style={{ backgroundColor: ColorPicker(background_color) }}
		>
			{section_heading && (
				<div className={Styles.headingContainer}>
					<PrismicRichText field={subheading} />
					<PrismicRichText field={heading} />
				</div>
			)}

			<div
				className={Styles.columnWrapper}
				style={{ flexDirection: image_right && !mobile && "row-reverse" }}
			>
				<div
					className={Styles.imageContainer}
					style={{
						width: !mobile && image_width && "40%",
						padding: !mobile && !full_image && "80px 5%",
					}}
				>
					<img src={image.url} alt={image.alt} />
				</div>
				<div
					className={Styles.contentContainer}
					style={{
						width: !mobile && image_width && "60%",
						paddingRight: !mobile && column_width && "5%",
					}}
				>
					{column_heading.length > 0 && (
						<span style={{ color: ColorPicker(column_heading_color) }}>
							<PrismicRichText field={column_heading} />
						</span>
					)}
					{column_subheading.length > 0 && (
						<span style={{ color: ColorPicker(column_subheading_color) }}>
							<PrismicRichText field={column_subheading} />
						</span>
					)}
					{description.length > 0 && (
						<span style={{ color: ColorPicker(description_color) }}>
							<PrismicRichText field={description} />
						</span>
					)}
					<LinkButton link={cta_link} className="btn" style={ctaStyles}>
						{cta_label}
					</LinkButton>
				</div>
			</div>
		</div>
	);
};

export default TwoColumns;
