import { Helmet } from "react-helmet-async";
const seo = ({
	primary: { page_title, page_description, structured_data },
}) => {
	return (
		<Helmet>
			<html lang="en" />
			<title>{page_title}</title>
			<meta name="description" content={page_description} />
			<script type="application/ld+json">{structured_data}</script>
		</Helmet>
	);
};

export default seo;
