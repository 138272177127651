import { PrismicRichText } from "@prismicio/react";
import Styles from "./TextBanner.module.css";

const TextBanner = ({
	primary: { image, heading, text, small_padding, wide_right_col },
}) => {
	return (
		<div
			className={`${Styles.wrapper} ${
				wide_right_col ? Styles.rightGrid : Styles.evenGrid
			}`}
			style={{
				padding: small_padding && "40px 5%",
			}}
		>
			<div className={Styles.leftContainer}>
				{image.url && <img src={image.url} alt={image.alt} />}
				<div>
					<PrismicRichText field={heading} />
				</div>
			</div>
			<div className={Styles.rightContainer}>
				<PrismicRichText field={text} />
			</div>
		</div>
	);
};

export default TextBanner;
