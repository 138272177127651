import Styles from "./ImageBanner.module.css";

const ImageBanner = ({ primary: { image, height } }) => {
	return (
		<div className={Styles.wrapper} style={{ minHeight: `${height}vh` }}>
			<img src={image.url} alt={image.alt} />
		</div>
	);
};

export default ImageBanner;
