const ColorPicker = (value) => {
	switch (value) {
		case "White":
			return "var(--background-light)";
		case "Green":
			return "var(--font-brand-green)";
		case "Black":
			return "var(--font-dark)";
		case "Light-Green":
			return "var(--light-green)";
		case "Dark-Green":
			return "var(--background-green)";
		case "Light-Grey":
			return "#F5F5F5";
		case "Grey":
			return "#E4E4E4";
		default:
			return null;
	}
};

export default ColorPicker;
