import { PrismicRichText } from "@prismicio/react";
import { ColorPicker, useWindowDimensions } from "../";
import Styles from "./ImageGrid.module.css";

const ImageGrid = ({
	primary: {
		heading,
		subheading,
		subheading_above,
		center_align,
		bottom_text,
		margin,
		columns,
	},
	items,
}) => {
	const { tablet, mobile } = useWindowDimensions();
	const gridStyles = {
		gridTemplateColumns: `repeat(${
			mobile ? "1" : tablet ? "2" : columns
		}, 1fr)`,
	};

	return (
		<div
			className={`${margin && Styles.wrapper} wrapper`}
			style={{ textAlign: center_align && "center" }}
		>
			{subheading_above && (
				<div style={{ marginBottom: "20px" }}>
					<PrismicRichText field={subheading} />
				</div>
			)}
			<PrismicRichText field={heading} />
			{!subheading_above && <PrismicRichText field={subheading} />}

			<div className={Styles.gridContainer} style={gridStyles}>
				{items.map(
					(
						{
							card_view,
							description,
							image,
							image_width,
							title,
							card_color,
							text_color,
							left_align_text,
						},
						index
					) => (
						<div
							className={Styles.card}
							key={index}
							style={{
								padding: card_view && "20px",
								backgroundColor: card_view && ColorPicker(card_color),
								color: text_color,
								textAlign: left_align_text && "start",
							}}
						>
							<img
								src={image.url}
								style={{
									width: `${image_width}px`,
								}}
								alt={image.alt}
							/>
							{(title.length > 0) | (description.length > 0) ? (
								<div className={Styles.cardText}>
									<PrismicRichText field={title} />
									<PrismicRichText field={description} />
								</div>
							) : null}
						</div>
					)
				)}
			</div>
		</div>
	);
};

export default ImageGrid;
