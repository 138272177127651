import { LinkButton } from "../";
import Styles from "./Header.module.css";

const Header = ({
	primary: {
		background_image,
		image_gradient,
		subheading,
		heading,
		description,
		cta_label,
		cta_link,
	},
}) => {
	return (
		<div
			className={`${Styles.wrapper} wrapper`}
			style={{
				backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.${image_gradient}), rgba(0, 0, 0, 0.${image_gradient})),
                    url(${background_image.url}`,
			}}
		>
			<div className={Styles.container}>
				<h5>{subheading}</h5>
				<h1>{heading}</h1>
				<h4>{description}</h4>
				<LinkButton
					link={cta_link}
					className={"btn"}
					style={{
						backgroundColor: "var(--background-light)",
						color: "var(--font-dark)",
						marginTop: "30px",
					}}
				>
					{cta_label}
				</LinkButton>
			</div>
		</div>
	);
};

export default Header;
