import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { PrismicProvider } from "@prismicio/react";
import { client } from "./prismic";
import "./global.css";
import App from "./App";

ReactDOM.render(
	<React.StrictMode>
		<PrismicProvider client={client}>
			<HelmetProvider>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</HelmetProvider>
		</PrismicProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
