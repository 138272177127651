import { useState } from "react";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from "react-places-autocomplete";

import Styles from "./AddressForm.module.css";

const AddressForm = ({ primary: { heading } }) => {
	const [address, setAddress] = useState("");
	const [step, setStep] = useState(1);

	const handleChange = (address) => {
		setAddress(address);
	};

	const handleSelect = (address) => {
		geocodeByAddress(address)
			.then((results) => getLatLng(results[0]))
			.then((latLng) => {
				console.log("Success", latLng, address);
				setAddress(address);
			})
			.catch((error) => console.error("Error", error));
	};

	const handleStep = (next) => {
		next && step < 3 && setStep(step + 1);
		!next && step > 1 && setStep(step - 1);
	};

	return (
		<>
			<div className={Styles.wrapper}>
				<div className={Styles.container}>
					<div className={Styles.headingContainer}>
						<h4>{heading}</h4>
						<div className={Styles.stepContainer}>
							<div className={Styles.step}>
								<div data-active-step={step === 1}>1</div>
								<p>Address</p>
							</div>
							<div className={Styles.step}>
								<div data-active-step={step === 2}>2</div>
								<p>Name</p>
							</div>
							<div className={Styles.step}>
								<div data-active-step={step === 3}>3</div>
								<p>Contact</p>
							</div>
						</div>
					</div>

					<form name="estimate" method="post">
						<input type="hidden" name="form-name" value="estimate" />
						<div
							className={Styles.inputContainer}
							style={{ display: step !== 1 && "none" }}
						>
							<PlacesAutocomplete
								value={address}
								onChange={handleChange}
								onSelect={handleSelect}
							>
								{({
									getInputProps,
									suggestions,
									getSuggestionItemProps,
									loading,
								}) => (
									<div style={{ width: "100%" }}>
										<input
											{...getInputProps({
												placeholder: "Type your address...",
											})}
											className={Styles.addressInput}
											value={address}
											name="address"
										/>
										<div className={Styles.suggestionContainer}>
											{loading && <div>Loading...</div>}
											{suggestions.map((suggestion, index) => {
												return (
													<div
														{...getSuggestionItemProps(suggestion, {})}
														key={index}
													>
														<span>{suggestion.description}</span>
													</div>
												);
											})}
										</div>
									</div>
								)}
							</PlacesAutocomplete>
						</div>

						<div
							className={Styles.inputContainer}
							style={{ display: step !== 2 && "none" }}
						>
							<input name="first-name" placeholder="First Name*" required />
							<input name="last-name" placeholder="Last Name*" required />
						</div>

						<div
							className={Styles.inputContainer}
							style={{ display: step !== 3 && "none" }}
						>
							<input name="email" placeholder="Email" type="email" />
							<input type="tel" name="phone" placeholder="Phone Number" />
						</div>

						<div className={Styles.btnContainer}>
							{step > 1 && (
								<button
									onClick={() => handleStep(false)}
									type="button"
									className={Styles.backBtn}
								>
									Back
								</button>
							)}
							{step < 3 && (
								<button onClick={() => handleStep(true)} type="button">
									Next
								</button>
							)}
							{step === 3 && <button type="submit">Submit</button>}
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default AddressForm;
