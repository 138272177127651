import { NavLink, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useSinglePrismicDocument } from "@prismicio/react";
import { useWindowDimensions } from "../";
import { Helmet } from "react-helmet-async";
import Styles from "./Nav.module.css";
import ColorPicker from "../ColorPicker";
import LinkButton from "../LinkButton";

const Nav = () => {
	const node = useRef();
	const [document, { state }] = useSinglePrismicDocument("nav");
	const [mobileNavOpen, setMobileNavOpen] = useState(false);
	const [alternateNav, setAlternateNav] = useState(false);
	const { tablet } = useWindowDimensions();
	const location = useLocation();

	const activeStyles = ({ isActive }) => ({
		borderBottom:
			(isActive && alternateNav && "1px solid green") ||
			(isActive && "1px solid white"),
	});

	const promoBannerStyles = {
		backgroundColor: ColorPicker(document?.data?.banner_color),
		color: ColorPicker(document?.data?.text_color),
		textAlign: "center",
		padding: "6px 10%",
	};

	const handleClickOutside = (e) => {
		if (node.current.contains(e.target)) {
			return;
		}
		setMobileNavOpen(false);
	};

	useEffect(() => {
		let altNavPages = [];
		state === "loaded" &&
			document.data.alternate_nav.forEach(({ page }) => {
				altNavPages.push(page.slug);
			});
		altNavPages.includes(location.pathname.replace(/^\/+/, ""))
			? setAlternateNav(true)
			: setAlternateNav(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, location]);

	useEffect(() => {
		mobileNavOpen
			? window.addEventListener("mousedown", handleClickOutside)
			: window.removeEventListener("mousedown", handleClickOutside);
		return () => {
			window.removeEventListener("mousedown", handleClickOutside);
		};
	}, [mobileNavOpen]);

	return (
		<>
			<Helmet>
				<link rel="icon" href={document?.data?.favicon?.url} />
			</Helmet>
			{document?.data?.show_promo && (
				<div style={promoBannerStyles}>
					{document?.data?.banner_link.link_type !== "Any" ? (
						<LinkButton
							link={document?.data?.banner_link}
							style={{ color: ColorPicker(document?.data?.text_color) }}
						>
							{document?.data?.banner_text}
						</LinkButton>
					) : (
						<p>{document?.data?.banner_text}</p>
					)}
				</div>
			)}

			<div
				className={Styles.wrapper}
				style={{
					overflow: alternateNav && "unset",
					position: alternateNav && "relative",
					height: alternateNav && "80px",
					overflowX: alternateNav && "clip",
				}}
				ref={node}
			>
				<FontAwesomeIcon
					icon={faBars}
					size="lg"
					className={`${Styles.mobileMenuBtn}`}
					onClick={() => setMobileNavOpen(!mobileNavOpen)}
					style={{
						transform: mobileNavOpen ? "translateX(60px)" : "translateX(0)",
						color: !alternateNav && "var(--font-light)",
					}}
				/>
				<div
					className={Styles.logo}
					style={{
						top: alternateNav && "50%",
						padding: alternateNav && "0",
						marginTop: alternateNav && "0",
					}}
				>
					<NavLink to="/" onClick={() => setMobileNavOpen(false)}>
						<img
							src={document?.data?.logo?.url}
							alt={document?.data?.logo?.alt}
						/>
					</NavLink>
				</div>

				<nav
					className={Styles.container}
					style={{
						transform:
							!tablet | mobileNavOpen ? "translateX(0%)" : "translateX(100%)",
						alignItems: alternateNav && !tablet && "center",
						backgroundColor: alternateNav && !tablet && "#fff",
					}}
				>
					<FontAwesomeIcon
						icon={faTimes}
						size="lg"
						className={`${Styles.mobileMenuBtn}`}
						onClick={() => setMobileNavOpen(!mobileNavOpen)}
					/>
					<NavLink
						to="/services"
						className={alternateNav ? Styles.link : Styles.whiteLink}
						style={activeStyles}
						onClick={() => setMobileNavOpen(!mobileNavOpen)}
					>
						Services
					</NavLink>
					<NavLink
						to="/about"
						className={alternateNav ? Styles.link : Styles.whiteLink}
						style={activeStyles}
						onClick={() => setMobileNavOpen(!mobileNavOpen)}
					>
						About
					</NavLink>
					<NavLink
						to="/contact"
						className={alternateNav ? Styles.link : Styles.whiteLink}
						style={activeStyles}
						onClick={() => setMobileNavOpen(!mobileNavOpen)}
					>
						Contact
					</NavLink>
					<a
						href="https://www2.lawngateway.com/SouthernRootsLawnCare"
						className={alternateNav ? Styles.link : Styles.whiteLink}
						target="_blank"
						rel="noopener noreferrer"
						// style={activeStyles}
						onClick={() => setMobileNavOpen(!mobileNavOpen)}
					>
						Pay Bill
					</a>
				</nav>
			</div>
		</>
	);
};

export default Nav;
