import { useSinglePrismicDocument } from "@prismicio/react";
import { SliceSwitch } from "../components";

const About = () => {
	const [document, { state }] = useSinglePrismicDocument("about");
	return (
		<>
			{state === "loading" ? (
				<h4 className="loading">LOADING...</h4>
			) : (
				document?.data.body.map((slice, index) => (
					<SliceSwitch slice={slice} key={index} />
				))
			)}
		</>
	);
};

export default About;
