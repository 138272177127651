const LinkButton = ({ link, style, children, className }) => {
	return (
		<>
			{link && link.link_type && link.link_type !== "Any" && (
				<a
					href={link.url || `/${link.slug}` || link}
					className={className}
					style={style}
					target={link.target}
				>
					<>{children}</>
				</a>
			)}
			{link && !link.link_type && (
				<a href={link} className={className} style={style}>
					<>{children}</>
				</a>
			)}
		</>
	);
};

export default LinkButton;
