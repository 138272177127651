import { PrismicRichText } from "@prismicio/react";
import Styles from "./ContactForm.module.css";

const ContactForm = ({ primary: { contact_information } }) => {
	return (
		<div className={Styles.wrapper}>
			<div className={Styles.container}>
				<PrismicRichText field={contact_information} />
			</div>
			<form name="contact" method="post" className={Styles.form}>
				<input type="hidden" name="form-name" value="contact" />
				<input
					type="text"
					name="name"
					placeholder="Full Name*"
					aria-label="Name"
					required
				/>
				<input
					type="email"
					name="email"
					placeholder="Email*"
					aria-label="Email"
					required
				/>
				<input
					type="tel"
					name="number"
					placeholder="Phone Number*"
					aria-label="Phone Number"
					required
				/>
				<textarea
					name="message"
					placeholder="What can we do for you?"
					aria-label="Message what we can do for you"
					rows="6"
				></textarea>
				<button type="submit">Submit</button>
			</form>
		</div>
	);
};

export default ContactForm;
