import { PrismicRichText } from "@prismicio/react";
import Styles from "./Comparison.module.css";

const Comparison = ({ primary: { heading, text, us, them } }) => {
	return (
		<div className={Styles.wrapper}>
			<div className={Styles.headingContainer}>
				<PrismicRichText field={heading} />
				<PrismicRichText field={text} />
			</div>
			<div className={Styles.comparisonContainer}>
				<div className={Styles.usContainer}>
					<PrismicRichText field={us} />
				</div>
				<span>vs</span>
				<div className={Styles.usContainer}>
					<PrismicRichText field={them} />
				</div>
			</div>
		</div>
	);
};

export default Comparison;
