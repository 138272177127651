import {
	Comparison,
	Header,
	ImageBanner,
	ImageGrid,
	Seo,
	TwoColumns,
	Steps,
	TextBanner,
	ContactForm,
	AddressForm,
} from ".";

const SliceSwitch = ({ slice }) => {
	switch (slice.slice_type) {
		case "seo":
			return <Seo {...slice} />;
		case "header":
			return <Header {...slice} />;
		case "image_grid":
			return <ImageGrid {...slice} />;
		case "image_banner":
			return <ImageBanner {...slice} />;
		case "two_columns":
			return <TwoColumns {...slice} />;
		case "comparison":
			return <Comparison {...slice} />;
		case "steps":
			return <Steps {...slice} />;
		case "text_banner":
			return <TextBanner {...slice} />;
		case "contact_form":
			return <ContactForm {...slice} />;
		case "address_form":
			return <AddressForm {...slice} />;

		default:
			return null;
	}
};

export default SliceSwitch;
