import { useSinglePrismicDocument } from "@prismicio/react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	// faInstagram,
	// faLinkedin,
	faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import Styles from "./Footer.module.css";

const Footer = () => {
	const [document] = useSinglePrismicDocument("footer");
	const year = new Date().getFullYear();

	return (
		<div className={Styles.wrapper}>
			<svg
				width="100%"
				height="100%"
				viewBox="0 0 100 100"
				preserveAspectRatio="none"
				stroke="none"
			>
				<path
					d="M0,0 C20,15  60,-10  100,6 L500,00 L0,0 Z"
					fill="var(--light-green)"
					style={{ stroke: "none" }}
					// vectorEffect="non-scaling-stroke"

					// style="stroke: none; fill:white;"
				></path>
			</svg>
			<div className={Styles.contentContainer}>
				<NavLink to="/">
					<img
						src={document?.data?.logo?.url}
						alt={document?.data?.logo?.alt}
					/>
				</NavLink>
				<div className={Styles.socialContainer}>
					{/* <a href="/instagram" target="_blank">
						<FontAwesomeIcon icon={faInstagram} size="2x" />
					</a>
					<a href="/linkedin" target="_blank">
						<FontAwesomeIcon icon={faLinkedin} size="2x" />
					</a> */}
					<a
						href="https://www.facebook.com/southernrootslawnTX/"
						target="_blank"
						rel="noreferrer"
					>
						<FontAwesomeIcon icon={faFacebook} size="2x" />
					</a>
				</div>

				<div className={Styles.navContainer}>
					<NavLink to="/services" className={Styles.link}>
						Services
					</NavLink>
					<NavLink to="/about" className={Styles.link}>
						About
					</NavLink>
					<NavLink to="/contact" className={Styles.link}>
						Contact
					</NavLink>
				</div>
				<span className={Styles.copyright}>
					&copy; {year} Southern Roots Lawn Care
				</span>
			</div>
		</div>
	);
};

export default Footer;
