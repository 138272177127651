import { Routes, Route, Navigate } from "react-router-dom";
import { Home, About, Services, Contact, NotFound } from "./pages/";
import { Nav, Footer } from "./components";

function App() {
	return (
		<div className="App">
			<Nav />
			<Routes>
				<Route exact path="/" element={<Navigate to="/home" />} />
				<Route index path="/home" element={<Home />} />
				<Route index path="/about" element={<About />} />
				<Route path="/services" element={<Services />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
			<Footer />
		</div>
	);
}

export default App;
