import { PrismicRichText } from "@prismicio/react";
import Styles from "./Steps.module.css";

const Steps = ({ primary: { heading, subheading }, items }) => {
	return (
		<div className={Styles.wrapper}>
			<div className={Styles.headingContainer}>
				<PrismicRichText field={heading} />
				<PrismicRichText field={subheading} />
			</div>
			<div className={Styles.stepsContainer}>
				{items.map((item, index) => (
					<div className={Styles.step} key={index}>
						<div className={Styles.stepText}>
							<h3 className={Styles.stepTitle}>{item?.step_title}</h3>
							<PrismicRichText field={item.step_text} />
						</div>
						<img src={item.step_image.url} alt={item.step_image.alt} />
					</div>
				))}
			</div>
		</div>
	);
};

export default Steps;
