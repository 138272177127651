import { useState, useEffect } from "react";

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	const tabletBreakpoint = 1024;
	const mobileBreakpoint = 768;
	let tablet = width <= tabletBreakpoint;
	let mobile = width <= mobileBreakpoint;

	return {
		width,
		height,
		mobile,
		tablet,
	};
}

export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowDimensions;
}
