import { LinkButton } from "../components";

const NotFound = () => {
	const wrapperStyles = {
		minHeight: "75vh",
		display: "flex",
		flexDirection: "column",
		gap: "40px",
		justifyContent: "center",
		alignItems: "center",
		paddingTop: "80px",
	};
	return (
		<div style={wrapperStyles}>
			<h1 style={{ textAlign: "center" }}>
				Uh Oh :( <br /> This page can not be found
			</h1>
			<LinkButton link={"/"} className={"btn"}>
				Go Back Home
			</LinkButton>
		</div>
	);
};

export default NotFound;
